var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { light: "", id: "main" } },
    [
      _c(
        "section",
        { staticClass: "section-container" },
        [
          _vm.isEmbeded != "true"
            ? _c(
                "v-row",
                { staticClass: "signin" },
                [
                  _c("v-col", {
                    staticClass:
                      "hidden-xs hidden-sm col-md-5 col-lg-1 col-xl-1 col",
                    attrs: { xs: "1" },
                  }),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-5 col-lg-5 cl-xl-5 col",
                      attrs: { xs: "5" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "auth-container pb-16 pb-sm-0" },
                        [
                          _c(
                            "vue-page-transition",
                            { attrs: { name: "fade-in-up" } },
                            [_c("router-view")],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.isEmbeded != "true"
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 cl-xl-6 col",
                          attrs: { xs: "6" },
                        },
                        [
                          _c("img", {
                            staticClass: "imgback",
                            attrs: { src: require("@/assets/bg-login.jpg") },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm.isEmbeded != "true" ? _c("ag-footer") : _vm._e(),
                ],
                1
              )
            : _c(
                "v-row",
                { staticClass: "d-flex justify-space-around" },
                [
                  _c("v-col", [
                    _c(
                      "div",
                      { staticClass: "alterLogin" },
                      [
                        _c(
                          "vue-page-transition",
                          { attrs: { name: "fade-in-up" } },
                          [_c("router-view")],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
      _vm.toast.show
        ? _c("toast", {
            attrs: {
              typeToast: _vm.toast.type,
              message: _vm.toast.message,
              topToast: _vm.toast.topToast,
            },
            on: { closeToast: _vm.closeToast },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }