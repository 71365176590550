<template>
  <v-app light id="main">
    <section class="section-container">
      <v-row class="signin" v-if="isEmbeded != 'true'">
        <v-col xs="1" class="hidden-xs hidden-sm col-md-5 col-lg-1 col-xl-1 col"> </v-col>
        <v-col xs="5" class="col-xs-12 col-sm-12 col-md-5 col-lg-5 cl-xl-5 col">
          <div class="auth-container pb-16 pb-sm-0">
            <vue-page-transition name="fade-in-up">
              <router-view />
            </vue-page-transition>
          </div>
        </v-col>
        <v-col xs="6" v-if="isEmbeded != 'true'" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 cl-xl-6 col">
          <img class="imgback" :src="require('@/assets/bg-login.jpg')" />
        </v-col>
        <ag-footer v-if="isEmbeded != 'true'" />
      </v-row>
      <v-row v-else class="d-flex justify-space-around">

        <v-col>
          <div class="alterLogin">
            <vue-page-transition name="fade-in-up">
              <router-view />
            </vue-page-transition>
          </div>
        </v-col>

      </v-row>
    </section>
    <toast
      v-if="toast.show"
      @closeToast="closeToast"
      :typeToast="toast.type"
      :message="toast.message"
      :topToast="toast.topToast"
    ></toast>
  </v-app>
</template>

<script>
import Toast from '@/router/views/commons/Toast.vue';
import { eventBus } from '../../main.js';

export default {
  components: {
    agFooter: () => import('./components/Footer'),
    Toast,
  },

  data() {
    return {
      isEmbeded: process.env.VUE_APP_EMBEDED,
      toast: {
        message: '',
        type: '',
        show: false,
      },
    };
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      eventBus.$on('openToast', (data) => {
        this.openToast(data.message, data.type, data.topToast);
      });
    },

    openToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
    },

    closeToast() {
      this.toast.show = false;
      this.toast.message = '';
      this.toast.typeToast = '';
      this.toast.topToast = '';
    },
  },
};
</script>

<style lang="scss">
#main {
  overflow: hidden !important;
}

.section-container {
  width: 100%;
  overflow: hidden !important;

  .signin {
    padding: 0;
    min-height: 100vh;

    .auth-container {
      position: relative;
      height: 100vh;
      overflow: hidden !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.imgback {
  object-fit: cover;
  object-position: 100%;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 1263px) {
  .imgback {
    object-position: 85%;
  }
}
</style>
